<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text class="d-flex">
        <v-avatar rounded size="120" class="me-6">
          <v-img :src="defautlPhoto()"></v-img>
        </v-avatar>
        <v-col cols="10">
          <div style="padding-top: 25px">
            <v-alert type="error">
              Pastikan anda memasukkan produk barang dengan benar di toko <strong> {{ showtokos.nama_toko }}</strong
              >.
            </v-alert>
          </div>
        </v-col>
      </v-card-text>

      <v-card-text>
        <!-- <v-form class="multi-col-validation mt-6"> -->
        <form ref="form" enctype="multipart/form-data" @submit.prevent="CreateData">
          <v-row>
            <v-col md="12" cols="12">
              <v-textarea
                v-model="showproduksjasa.keterangan"
                clearable
                clear-icon="mdi-close-circle"
                label="Keterangan"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12">
              <div style="float: left"><strong>DETAIL PRODUK</strong> &nbsp;</div>
              <hr />
            </v-col>
            <!-- <v-col cols="12" md="6">
              <v-text-field
                type="number"
                :rules="[rulesstok.numberRule]"
                dense
                label="Stok"
                v-model="showproduksjasa.stok"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                type="number"
                :rules="[rulesberat.numberRule]"
                dense
                label="Berat"
                v-model="showproduksjasa.berat"
                outlined
              ></v-text-field>
            </v-col> -->
            <v-col cols="12" md="6">
              <CurrencyInput
                dense
                outlined
                v-model="showproduksjasa.harga"
                label="Price (Rp)"
                currency="IDR"
                locale="id"
                class="form-control"
              />
            </v-col>
            <v-col md="12" cols="12">
              <v-textarea
                v-model="showproduksjasa.keteranganjasa"
                clearable
                clear-icon="mdi-close-circle"
                label="Keterangan Jasa"
              ></v-textarea>
            </v-col>
            <v-col md="12" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Gambar Produk </v-card-text>
              <v-row>
                <v-col cols="2" sm="2" style="cursor: pointer" v-for="(gambar, index) in gambarproduks" :key="index">
                  <v-img
                    :src="
                      showGambarProduk() + '/images/produk/jasa/' + gambar.id_produkjasa + '/' + gambar.photo_produkjasa
                    "
                    @click="deleteGambarProduk(gambar.id_detailprodukjasa, index)"
                  >
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <div>
                <span
                  ><v-chip class="ma-2" color="error" text-color="white"> *Klik gambar untuk menghapus! </v-chip></span
                >
              </div>
              <v-btn type="submit" color="success" class="me-3 mt-4" @click.prevent="showFileChooser">
                Choose Image
              </v-btn>
              <input
                ref="inputprodukbarang"
                style="display: none"
                id="photo_produkjasa"
                type="file"
                accept="image/*"
                :name="gambarprodukbarang.photo_produkjasa"
                @change="setImagePhotoProduct"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-header>Tersedia</v-header>
              <v-radio-group v-model="showproduksjasa.tersedia" row>
                <v-radio label="Yes" value="Y" color="info"></v-radio>
                <v-radio label="No" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'tokos-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiCheckBold,
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiDelete,
  mdiGreasePencil,
  mdiContentSave,
  mdiSlashForward,
  mdiStoreEdit,
} from '@mdi/js'

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
  },
  data() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Visa') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Members') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }
    return {
      resolveStatisticsIconVariation,

      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Toko',
          disabled: false,
          href: '/admin/tokos',
        },
        {
          text: 'Produk',
          disabled: true,
        },
      ],

      // icons
      icons: {
        mdiCheckBold,
        mdiGreasePencil,
        mdiDelete,
        mdiContentSave,
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiSlashForward,
        mdiStoreEdit,
      },

      showtokos: [],
      showproduksjasa: {
        keterangan: '',
        tersedia: 'Y',
        harga: '',
        keteranganjasa: '',
      },

      gambarprodukbarang: {
        photo_produkjasa: [],
      },

      rulesstok: {
        numberRule: val => {
          if (val < 0) return 'Please enter a positive number'
          return true
        },
      },

      rulesberat: {
        numberRule: val => {
          if (val < 0) return 'Please enter a positive number'
          return true
        },
      },
    }
  },

  mounted() {
    this.GetAllProduk()
    this.$isLoading(true)
    this.defautlPhoto()
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  filters: {
    capitalize: function (data) {
      var capitalized = []
      data.split(' ').forEach(word => {
        capitalized.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      })
      return capitalized.join(' ')
    },
  },
  methods: {
    CreateData(e) {
      if (this.$data.showproduksjasa.keterangan.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Keterangan required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.showproduksjasa.tersedia.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Tersedia required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.showproduksjasa.keteranganjasa.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Keterangan jasa required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri =
          process.env.VUE_APP_ROOT_API +
          `/api/admin/produkjasas/${this.$route.params.id_produk}/${this.$route.params.id_produkjasa}`
        this.axios
          .post(uri, {
            keterangan: this.showproduksjasa.keterangan,
            tersedia: this.showproduksjasa.tersedia,
            keteranganjasa: this.showproduksjasa.keteranganjasa,
            harga: this.showproduksjasa.harga,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Produk created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            setTimeout(() => {
              this.$router.push({ name: 'tokos-index' })
            }, 3000)
          })
        e.preventDefault()
      }
    },

    showFileChooser() {
      this.$refs.inputprodukbarang.click()
    },

    setImagePhotoProduct(e) {
      if (e.target.files[0].size > 2000000) {
        this.$swal.fire({
          title: 'Warning!',
          text: 'File size too large! File size must be smaller than 2MB!',
          icon: 'warning',
          showCancelButton: false,
          showConfirmButton: true,
        })
        return false
      }

      const readerPhoto = new FileReader()
      readerPhoto.readAsDataURL(e.target.files[0])
      readerPhoto.onload = res => {
        const dataproduk = {
          photo_produkjasa: res.target.result,
          id_produkjasa: this.$route.params.id_produkjasa,
        }

        const uristorephotoproduk = `${process.env.VUE_APP_ROOT_API}/api/admin/gambarprodukjasa/store`
        this.axios.post(uristorephotoproduk, dataproduk).then(response => {
          this.gambarprodukbarang.photo_produkjasa.push(res.target.result)

          setTimeout(() => {
            if (response.status == 200) {
              this.GetAllProduk()
            }
          }, 3000)
        })
      }
    },

    defautlPhoto() {
      const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/icon/tokobarang.png`
      return imageDefault
    },

    showGambarProduk() {
      const imageProduks = `${process.env.VUE_APP_ROOT_API}`
      return imageProduks
    },

    GetAllProduk() {
      let uri = process.env.VUE_APP_ROOT_API + `/api/admin/produkjasas/${this.$route.params.id_produk}/showprodukjasa`
      this.axios.get(uri).then(response => {
        this.showproduksjasa = response.data.showprodukjasa
        this.showtokos = response.data.showtoko
        this.gambarproduks = response.data.gambarproduk
      })
    },

    deleteGambarProduk(id, index) {
      // splice(start, length)
      let uridelete = process.env.VUE_APP_ROOT_API + `/api/admin/produkjasas/${id}`
      this.axios.delete(uridelete).then(response => {
        this.gambarproduks.splice(index, 1)
        setTimeout(() => {
          if (response.status == 200) {
            this.GetAllProduk()
          }
        }, 3000)
      })
    },
  },
  watch: {},
}
</script>
